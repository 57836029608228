import React, {useState} from "react";
import "../scss/Main.scss";
import Layout from '../components/Layout';

import Landing from '../sections/Landing';
import About from '../sections/About';
import Services from '../sections/Services';
import ProjectGallery from '../sections/ProjectGallery';
import Process from "../sections/Process";
import CallToAction from '../components/CallToAction';

export default function Index({location}) {

  const [lightboxOn, setLightBox] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0); 

  return(
    <div className={`home-container`}>
      <Layout index={galleryIndex} setIndex={setGalleryIndex} lightboxOn={lightboxOn} setLightBox={setLightBox} pageName={location.pathname}>
        <Landing/>
        <About/>
        <Process/>
        <CallToAction/>
        <Services/>
        <ProjectGallery title="FEATURED PROJECTS" setIndex={setGalleryIndex} setLightBox={setLightBox}/>
      </Layout>
    </div>
  )
}


