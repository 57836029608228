import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Button from '../components/Button';

export default function About() {
    return (
        <div id="AboutUs" className="about-wrapper">
            <div className="spacer10V"></div>
            <div className="about-container">
                
                <div className="about-text-container">
                    <h1>Who We Are</h1>
                    <p>
                        NorthStar Home Solutions is an experienced, locally owned, full-service home improvement provider serving Northern Virginia, Maryland, and D.C. 
                        At NorthStar, we pride ourselves on being able to consistently give our clients unparalleled quality results at competitive prices without compromising the quality of service.
                        Our highly skilled team excels in handling complex renovation projects with strict and rigorous requirements. 
                        From simple drywall repair to complete basement remodels, NorthStar’s project managers carefully plan each project to be completed as efficiently and trouble free as possible. {` `} 
                    </p>
                    {/* <p>
                        NorthStar Home Solutions is an experienced, locally owned, full-service home improvement provider serving Northern Virginia, Maryland, and D.C. 
                        As a fully licensed and insured general contractor, NorthStar is equipped to handle complex renovation projects like additions, complete basement remodels, all the way down to simple drywall repairs. 
                        Our Attention to detail is what sets us apart from our competitors, and our hands-on approach results in incredible workmanship and highly satisfied clients. 
                        Give us a call, text, or send us an email. We know you will be glad you did. 
                    </p> */}
                    <div className="button-wrapper">
                        <Button buttonType="1" linkType="gatsby" to="/contact" text="GET IN TOUCH &rarr;"></Button>
                    </div>
                </div>
                
                <StaticImage
                    src="../images/constructionTeamCropped.jpg"
                    alt=""
                    layout="constrained"
                    loading="lazy"
                    placeholder="blurred"
                    format={["auto", "webp"]}
                    objectFit="contain"
                    className="about-image-container"

                />
            </div>
        </div>
    )
}
