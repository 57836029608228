import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

export default function Process() {
    return (

        <div className="process-wrapper">
            <div className="process-headline-container">
                <div id="top-line"></div>
                <h1 className="desktop-h1">STREAMLINE YOUR CONSTRUCTION EXPERIENCE</h1>
                <h1 className="mobile-h1">STREAMLINE YOUR</h1>
                <h1 className="mobile-h1">CONSTRUCTION EXPERIENCE</h1>
                <h1>WITH NORTHSTAR</h1>
                <div id="bottom-line"></div>
            </div>
            <p>
                NorthStar’s simple three step process guarantees a smooth project experience from our initial consultation to our final walkthrough and beyond. 
                Our exceptional team of professionals will work with you side by side to provide you with the best results possible. 
                You can expect creative thinking, detailed planning, and remarkable project management. 
                After our first consultation you will have an idea of what your project will look like, how much it will cost, and how long it will take. 
                Here at NorthStar we strive to become your trusted partner for home improvements by delivering your project on-time and within budget.
            </p>
            <div className="process-steps-container">

                <div className="process-step">
                    <div className="step-headline">
                        <h1><span>01</span> &emsp; CONSULT</h1>
                        <div className="process-bottom-line"></div>
                    </div>
                    <div className="step-content">
                        <div className="image-container">
                            <StaticImage 
                                src="../images/ideas.jpg"
                                alt="image of a lady holding a lightbulb"
                                height="500"
                                width="800"
                                loading="eager"
                                placeholder="none"
                                format={["auto","webp"]}
                                layout="constrained"
                            />
                        </div>
                        <div className="text-container">
                            <h2>Discuss Your Ideas</h2>
                            <p>
                                Our process begins with a complimentary on-site evaluation to take a look at your home. 
                                During our visit we will discuss your project ideas, goals, and vision in order to determine your projects feasibility, and discover any potential challenges or constraints. 
                                While in your home we will also discuss your desired timeline for the project and your budget, as well as take measurements, and if necessary, take pictures. 
                                Our goal at this meeting is to answer any and all questions you may have, and to get a feeling for what it will be like to work together. 
                            </p>
                        </div>
                    </div>
                </div>

                <div className="process-step">
                    <div className="step-headline">
                        <h1 id="step2-header"><span>02</span> &emsp; DESIGN</h1>
                        <div className="process-bottom-line"></div>
                    </div>
                    <div className="step-content step2">
                        <div className="image-container step2">
                            <StaticImage 
                                src="../images/kitchenDesign.jpg"
                                alt="image of a lady holding a lightbulb"
                                height="500"
                                width="800"
                                loading="eager"
                                placeholder="none"
                                format={["auto","webp"]}
                                layout="constrained"
                            />
                        </div>
                        <div className="text-container">
                            <h2>Design a Solution</h2>
                            <p>
                                Next, we gather the information we discovered during our consultation stage to design a unique proposal to fit both your vision and budget. 
                                We start the design process by creating a scaled drawing of the layout of your project space. 
                                Along with our design drawing, you will receive a complete proposal that includes: a general description of the scope of work, a detailed estimate, as well as an estimated project timeline. 
                                Together we will explore design concepts and material options, and with your feedback we will refine our plans to hone in on a perfect solution. 
                            </p>
                        </div>
                    </div>
                </div>

                <div className="process-step">
                    <div className="step-headline">
                        <h1><span>03</span> &emsp; BUILD</h1>
                        <div className="process-bottom-line"></div>
                    </div>
                    <div className="step-content">
                        <div className="image-container">
                            <StaticImage 
                                src="../images/kitchen.jpg"
                                alt="image of a lady holding a lightbulb"
                                height="500"
                                width="800"
                                loading="eager"
                                placeholder="none"
                                format={["auto","webp"]}
                                layout="constrained"
                            />
                        </div>
                        <div className="text-container">
                            <h2>Bring Your Ideas To Life</h2>
                            <p>
                                Once all project details are reviewed and agreed upon, we will approve and finalize all necessary documents needed to begin the construction phase. 
                                During the building phase you can expect all demolition, new installations, and construction work to be completed under the careful supervision of our exceptional project managers. 
                                Our project managers will make sure everything runs smoothly by handling ongoing on-site adjustments, maintaining a clean and safe work site, and addressing any questions or new ideas you may have.
                                Once all work has been completed, you’ll do a final walk-through with one of our production managers to ensure that we satisfied and exceeded all your expectations.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
