import React from 'react';
import Title from '../components/Title';
import PhotoGallery from '../components/PhotoGallery';
import Button from '../components/Button';

export default function ProjectGallery(props) {
    return (
        <div id="ProjectGallery" className="project-gallery-container">
            <h1>Featured Projects</h1>
            <div className="photo-gallery-wrapper">
                <PhotoGallery setIndex={props.setIndex} setLightBox={props.setLightBox}/>
                {/* {(props.button) ? 
                
                <div className="button-wrapper">
                    <Button otherClassName="gallery-button" to="/gallery" text="VIEW MORE &thinsp; &rarr;" linkType="gatsby" />
                </div> 
                
                :
                
                ""} */}

            </div>
        </div>
    )
}
