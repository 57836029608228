import React from 'react';
import Title from '../components/Title';
import ServiceCards from '../components/ServiceCards';

export default function Services() {
    return (
        <div className="service-container">
            <div id="Services" className="spacer10VH"></div>
            <div className="text-container">
                <h1>What We Do</h1>
            </div>
            <div className="service-cards-background-container">
                <ServiceCards/>
            </div>
            
        </div>
    )
}
