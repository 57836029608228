import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import Button from '../components/Button';


export default function CallToAction() {
    return (
        <div className="call-to-action-container">
            <StaticImage
                src="../images/contact.jpg"
                alt="background picture of different colored helmets"
                layout="constrained"
                loading="lazy"
                placeholder="dominantColor"
                format={["auto", "webp"]}
                className="background-image"
                objectPosition="50% 50%"
                
               
            />
            <div className="text-container">
                <h1>
                    TAKE THE FIRST STEP
                </h1>
                <Button buttonType="3" linkType='anchor' to="tel:+15713546067" text="GIVE US A CALL &thinsp; &rarr;"/>
            </div>
        </div>
    )
}
