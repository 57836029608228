import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
 
export default function PhotoGallery(props) {

    const data = useStaticQuery(graphql`
        query galleryImages{
            allFile(
                filter: {relativeDirectory: {eq: "galleryImages"}}
                sort: {fields: name, order: ASC}
              ) {
                edges {
                  node {
                    childImageSharp {
                      gatsbyImageData(formats: AUTO, layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                      fields {
                        exif {
                          raw {
                            image {
                              ImageDescription
                            }
                          }
                        }
                      }
                    }
                    name
                  }
                }
              }
        }
    `);

    function handleClick(index){
      props.setIndex(index);
      props.setLightBox(true);
      if(typeof document !== 'undefined'){
        document.body.classList.add("no-scroll");
      }
    } 

    return (
        <div className="photo-gallery-container">
            {data.allFile.edges.map((edge, index) =>{
                return(
                    <div className={`item${index} grid-item`} key={`g-item${index}`} onClick={() => handleClick(index)}>
                        <div className="img-overlay">
                          <div className="photo-gallery-text-container">
                            <h1>{edge.node.name.substring(1)}</h1>
                            <h2>{edge.node.childImageSharp.fields.exif.raw.image.ImageDescription.match("[a-z | A-Z]*,[a-z | A-Z]*")}</h2>
                          </div>
                          {/* <FontAwesomeIcon icon={faSearch} className="zoom-icon"/> */}
                        </div>
                        <GatsbyImage 
                            className={`gallery-img`}
                            image={getImage(edge.node)}
                            alt={`${edge.node.childImageSharp.fields.exif.raw.image.ImageDescription}`}
                            objectPosition="50% 50%"
                        />
                    </div>
                )
            })}
        </div>
    )
}
